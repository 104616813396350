@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Courgette&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway+Dots&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sixtyfour&display=swap');
$deep: #35254a;
$primary: #7851a9;

*{
    font-family: "Faculty Glyphic", monospace;
}

.home .content .left .image{
    background-image: linear-gradient(145deg, #7951a902, #3e23605c), url(./assets/isaac-i.png) !important;
    background-image: linear-gradient(#0000, #3e23605c), url(./assets/isaac-i.png) !important;
    // animation: background-color-change 4s ease-in-out infinite alternate;

    .bg{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $deep;
        top: 0;
        left: 0;
        display: flex;
        z-index: 0;
        opacity: 1 !important;
        visibility: visible !important;
        border-radius: 10000px;
        background-image: url(./assets/banner.jpeg);
        background-size: cover;
        background-position: center;
    }

    .profile{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        z-index: 2;
        opacity: 1 !important;
        visibility: visible !important;
        border-radius: 10000px;
        background-image: url(./assets/isaac-i.png);
        background-size: cover;
        background-position: center;
    }
    
    .secondary-links{
        backdrop-filter: grayscale(10);
        // opacity: 1 !important;
        // visibility: visible !important;
        position: relative;
        z-index: 7 !important;
        border-radius: 1000px;
        background-color: #0002;
    }

    &::before{
        z-index: 6;
        // display: none !important;
    }

    &::after{
        z-index: 3;
        pointer-events: none;
        border: 2px dotted #0004;
        box-shadow: none;
        animation: rotate 2s linear infinite;
    }
    @keyframes rotate {
        0% { rotate: 0deg; }
        100% { rotate: 360deg; }
    }
}

.player-bg{
    position: fixed;
    z-index: 51;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // backdrop-filter: blur(20px);
    // background-image: linear-gradient(transparent, #000a);
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    display: none;

    .music-player{
        width: calc(100% - 40px);
        position: absolute;
        bottom: 20px;
        background-color: rgba(#fff, 0.2);
        border-radius: 40px;
        border: 1px solid #0002;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        gap: 10px;
        padding: 5px;
        backdrop-filter: blur(10px);
        pointer-events: all;
        max-width: 400px;
        transition: all 1s ease;
        right: 20px;

        &.not-focused{
            width: 72px;
            width: 180px;
            right: 50px;
            .details{
                opacity: 0.3;
                transition: all 0.3s ease;
            }

            &:hover{
                right: 20px;
                width: calc(100% - 40px);
                .details{
                    opacity: 1;
                }
            }

        }

        .img-holder{
            width: 60px;
            min-width: 60px;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000c;
            border-radius: 1000px;
            background-image: linear-gradient(45deg, $deep, $primary, $deep);
            animation: rotate-fvr 10s linear forwards infinite;

            @keyframes rotate-fvr{
                0%{
                    transform: rotate(-360deg);
                }
            }

            img{
                opacity: 0;
            }
        }

        .details{
            width: 100%;
            display: flex;
            flex-direction: column;
            min-width: max-content;

            h3{
                font-size: 20px;
            }

            p{
                font-size: 16px;
                opacity: 0.7;
            }
        }

        .controls{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-right: 5px;

            button{
                background-color: transparent;
                min-width: 40px;
                min-width: 50px;
                aspect-ratio: 1/1;
                border-radius: 1000px;
                border: 1px solid #0002;
                color: $deep;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 200%;
            }
        }

        @media screen and (max-height: 1000px) {
            .img-holder{
                width: 50px;
                min-width: 50px;
            }
        }
    }
}

@keyframes background-color-change {
    0% { background-color: $primary; }
    50% { background-color: $deep; }
    100% { background-color: $primary; }
}

.cryptic-animation {
    animation: crypticEffect 1s infinite alternate;
}

// @keyframes crypticEffect {
//     0% { opacity: 1; }
//     50% { opacity: 0.5; transform: scale(1.1); }
//     100% { opacity: 1; }
// }

.fast-lane{
    gap: 30px !important;
    
    @media screen and (max-width: 700px){
        gap: 10px !important;
        justify-content: space-evenly !important;
    }
}

.home{
    .content{
        padding: 0 80px;

        @media screen and (max-width: 660px) {
            padding: 0 20px;
        }
    }
    @media screen and (max-height: 750px) {
        .content{
            gap: 10px !important;

            .left{
                margin-top: 40px;
                margin-bottom: -10px;
                .image{
                    width: 200px !important;
                    min-width: 200px !important;
                    max-width: 200px !important;
                    height: 200px !important;
                    min-height: 200px !important;
                    max-height: 200px !important;
                }
            }
        }
    }
}

.footer{
    @media screen and (max-width: 700px) {
        bottom: 0;
        transform: translateY(100%) !important;
    
        #comeup{
            position: absolute !important;
            top: -70px !important;
        }
        
        &.show{
            transform: translateY(0) !important;
            #comeup{
                transform: rotate(180deg);
            }
        }
    }
}

button{
    cursor: pointer;

    &:focus{
        scale: 1.1;
    }
}

.menu{
    transform: translateX(0) !important;
    opacity: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 100%;
    border-radius: 0px;
    transition: all 0.5s ease;
    background-color: rgba($primary, 0.8);
    background-image: linear-gradient($primary, transparent);
    backdrop-filter: blur(30px) !important;

    &.show{
        opacity: 1;
        pointer-events: all;
    }

    .mobile-links-holder{
        gap: 10px;
    }

    .mobile-link{
        width: fit-content;
        font-size: 20px;
        color: #fff;
        padding: 10px 15px;
        height: fit-content;
        font-weight: 300;
        
        &.active{
            color: #fff;
            font-size: 25px;
            height: fit-content;
            padding: 10px 15px;
            background-color: transparent;
        }
    }
}

.showing-portfolio, .showing-contact{
    .menu{
        background-color: #fffa;
        background-image: linear-gradient(#fff, transparent);

        .mobile-link{
            width: fit-content;
            font-size: 20px;
            color: $deep;
        
            &.active{
                color: $deep;
                font-size: 25px;
                background-color: transparent;
            }
        }
    }
    
    .hamburger{
        border: 2px solid #fff0;
    
        &.show{
            border: 2px solid #fff4;
            background-color: transparent !important;
            border: 2px solid $deep;
        }
    }
}

// .home .content .right .name .fa-certificate{
//     color: #000;
// }

// .home .content .right .name .fa-check{
//     color: rgb(255, 208, 0);
// }

.hamburger{
    border: 2px solid #fff0;

    &::before, &::after {
        pointer-events: none;
    }

    &.show{
        border: 2px solid #fff4;
        background-color: transparent !important;
    }
}

.scrollbar{
    &.show{
        transform: translateX(0);
        visibility: visible;
        opacity: 0;
        pointer-events: none;
    }
}

#comeup{
    color: #fff;
    background-color: #fff2;
    border: 2px solid #fff4;
    cursor: pointer;
}

.home{
    background-image: linear-gradient(#fff, #fff);

    .image{
        position: relative;
        overflow: visible !important;
        border-radius: 1000px !important;
        background-color: $primary;
        box-shadow: none !important;

        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 1000px;
            transform: scale(1.1);
            pointer-events: none;
            background-color: transparent;
            box-shadow: 0px 0px 0px 2px rgba($primary, 0.1);
            // display: none;
        }

        &::before{
            border-radius: inherit;
        }
    }

    .download.cv{
        padding: 10px 30px !important;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: transparent !important;
        border: 2px solid #000;
        color: #000 !important;
        font-weight: 600;
        font-size: 18px;
        box-shadow: none !important;

        &:hover{
            background-color: #0002 !important;
        }
    }
}

.navbar{
    transform: translateY(20px);
    width: fit-content;
    .content{
        border: 1px solid #0002;
        max-width: 1000px;
        border-radius: 1000px;
        padding: 10px;

        @media screen and (max-width: 950px) {
            border: none;
            background-color: transparent !important;
        }

        .logo{
            min-width: 60px;
            font-weight: 600;
            font-family: "Roboto Slab", monospace;
        }

        .links-holder{
            gap: 20px;
            .link{
                height: 45px;
                border-radius: 1000px;
            }
        }
    }
}

.work{
    text-align: center !important;
}

.cardano{
    background-color: #fff !important;
}

.ethereum{
    background-color: #7851a9 !important;
    padding: 0px !important;
}

.nextjs{
    padding: 0px !important;
    img{
        border-radius: 1000px;
    }
}
.portfolio{
    .heading{
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 30;
        width: 100%;
        background-color: rgba($primary, 0.8);

        h1{
            margin-bottom: 30px;
        }

    }
}

.switch-lang, .switch-langg{
    display: none !important;
}

@media screen and (max-width: 2600px) {
    .portfolio{
        .heading{
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 30;
            width: 100%;
             backdrop-filter: blur(20px);
            background-color: rgba($primary, 0.8);
            padding-top: 40px;
            
            @media screen and (max-width: 1000px) {
                border-bottom: 2px solid #fff1;
                h1{
                    margin-bottom: 0px;
                }
                .filter-bar{
                    &::before{
                        display: none;
                    }
                }
                
            }
        }
        .projects{
            padding-top: 400px;
            
            @media screen and (max-width: 750px) {
                padding-top: 250px;
            }
        }
    
    }
}

.tailwind{
    background-color: rgb(255, 255, 255) !important;
}

.services{
    background: transparent;
    // background-image: linear-gradient(#fff0, $primary);

    .sides{
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 31;
        top: 0;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 40px;

        .arrow{
            position: absolute;
            width: 2px;
            height: 30%;
            max-height: 200px;
            background-color: #0000;
            transition: all 0.5s ease;

            .head{
                pointer-events: all;
                cursor: pointer;
                transition: all 0.3s ease;

                &:hover{
                    scale: 1.1;
                }
            }
            
            &.left{
                left: 40px;
                top: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: linear-gradient($deep, transparent);
                transform: translateY(-100%);
                opacity: 0;

                @media screen and (max-width: 600px) {
                    left: 30px;
                }

                .head{
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 10px;
                    height: 10px;
                    rotate: 45deg;
                    background-color: $deep;
                    border: 1px solid $deep;
                }
            }
            
            &.right{
                right: 40px;
                bottom: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: linear-gradient(transparent, $deep);
                transform: translateY(100%);
                opacity: 0;

                @media screen and (max-width: 600px) {
                    right: 30px;
                }
                
                
                .head{
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    background-color: $deep;
                    border: 1px solid $deep;
                    rotate: 45deg;
                    bottom: 0;
                }
            }
        }
    }

    .heading{
        background-color: #fffa !important;
    }

    .content{
        max-width: 1200px;
        padding-left: 40px;
        padding-right: 40px;
        gap: 40px;

        .service{
            background-color: rgba($primary, 0.1);
            border: 1px solid #0002;
            box-shadow: none;
            
            .icon{
                box-shadow: 0 0 0 1px #0002;
                border: 4px solid #fff;
            }
        }
    }
    @media screen and (max-width: 1000px) {
        padding-top: 100px;
        .heading{
            position: fixed;
            top: 0;
            padding-top: 50px;
            background-color: #fff2;
            z-index: 30;
            backdrop-filter: blur(20px);
        }
    }

}

.showing-services{
    .services{
        .sides{
            .arrow{
                transform: translateY(0) !important;
                opacity: 1 !important;
            }
        }
    }
}